<template>
  <div class="sign-up-confirm">
    <div class="kcxx-box">
      <courseInfo :detail="courseDetail" :form="form" show-flag="qrbm" />
    </div>
    <div class="footer-info-btn">
      <div class="payment-amount">
        支付金额：<span> ¥{{ courseDetail.price }}</span>
      </div>
      <div class="right-away-sign" :class="{ disabled: qrbmDis }" @click="ljbmOnClick">
        {{ qrbmDis ? '已截止报名' : '确认报名' }}
      </div>
    </div>
  </div>
</template>

<script>
import { userInfoUrl, getCourseDetailUrl, saveCourseApplyInfoUrl } from '../api.js';
import courseInfo from '../components/courseInfo.vue';
import { mapState } from 'vuex';

export default {
  name: 'signUpConfirm',
  components: { courseInfo },
  data() {
    return {
      courseDetail: {
        courseName: '',
        courseSettingList: [],
        courseOpenStart: '',
        courseOpenEnd: '',
        applyDateStart: '',
        applyDateEnd: '',
        teacher: '',
        isAstrictPersons: void 0,
        minPerson: void 0,
        maxPerson: void 0,
        courseAddr: '',
        courseAdmin: '',
        courseAdminMobile: '',
        price: void 0
      },
      form: {
        tenantId: '',
        courseId: '',
        userId: '',
        userName: '',
        mobileNum: '',
        payType: 1,
        chargeType: 1,
        payAmount: void 0
      },
      timer: null,
      currentDate: new Date()
    };
  },
  computed: {
    ...mapState(['userId', 'tenantId', 'communityId', 'houseId']),
    qrbmDis() {
      return this.currentDate.getTime() > new Date(this.courseDetail.applyDateEnd).getTime();
    }
  },
  created() {
    this.form.courseId = this.$route.query.id;
    this.$axios
      .get(userInfoUrl, { params: { userId: this.userId } })
      .then(res => {
        if (res && res.code === 200) {
          this.form.tenantId = this.tenantId;
          this.form.userId = res.data.userId;
          this.form.userName = res.data.userName;
          this.form.mobileNum = res.data.mobile;
        }
        this.getDetail();
      })
      .catch(() => {
        this.getDetail();
      });
    this.satrtInterval();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    satrtInterval() {
      if (this.timer) clearImmediate(this.timer);
      this.timer = setInterval(() => {
        this.currentDate = new Date();
      }, 1000);
    },
    getDetail() {
      this.$axios
        .get(getCourseDetailUrl, { params: { id: this.$route.query.id } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            Object.assign(this.courseDetail, res.data);
            this.form.payAmount = res.data.price;
          }
        })
        .catch(() => {});
    },
    ljbmOnClick() {
      if (this.qrbmDis) return this.$toast('报名时间已截止');
      if (['', void 0, null, NaN].includes(this.form.userName)) return this.$toast('请填写报名人');
      if (this.form.userName.length > 10) return this.$toast('报名人最长10个字符');
      if (['', void 0, null, NaN].includes(this.form.mobileNum)) return this.$toast('请填写联系电话');
      if (!/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.mobileNum)) return this.$toast('请填写正确的联系电话');
      this.$axios
        .post(saveCourseApplyInfoUrl, this.form)
        .then(res => {
          if (res && res.code === 200) {
            this.$dialog
              .alert({
                title: '提示',
                message: '报名成功！可在【我的课程】列表查看报名详情，点击确认前往订单详情。',
                confirmButtonColor: '#3680FF'
              })
              .then(() => {
                this.$router.replace({
                  name: 'myCourseOrderDetail',
                  query: { id: res.data }
                });
              });
          }
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.sign-up-confirm {
  box-sizing: border-box;
  height: 100%;
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  background-color: #fafafa;
  padding-bottom: 100px;
}
.kcxx-box {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.footer-info-btn {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  bottom: 0px;
  bottom: constant(safe-area-inset-bottom);
  bottom: env(safe-area-inset-bottom);
  padding: 0px 0px 0px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .payment-amount {
    color: #333333;
    font-size: 30px;
    line-height: 30px;
    span {
      color: #d9021b;
      font-size: 32px;
      font-weight: 700;
    }
  }
  .right-away-sign {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 260px;
    height: 100%;
    background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    line-height: 30px;
    &.disabled {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
      pointer-events: none;
    }
  }
}
</style>
